import React from "react";
import Principal from '../components/principal';
import Barra from '../components/barra';
import Plan from '../components/plan';
import Contenido from '../components/contenido';
import Porque from '../components/porque';
import Metodos from '../components/metodos';
import Servicios from "../components/servicios";
import PromotionalSection from "../components/PromotionalSection ";
import Clientes from "../components/clientes"
import ProductCarousel from "../components/plantillas";
export const Home = ()=>{
    return (
        <div className="mb-5 w-screen todoo">
           <Principal/>
 <Barra/>
 {/*Aqui*/}
 {/* <Plan/> */}

<Servicios/>
{/* <ProductCarousel/> */}
<Clientes/>

<PromotionalSection/>
 {/* <Contenido/> */}
 {/* <Metodos/> */}
 {/* <SomosLaSolicion/> */}
 {/* <Porque/> */}

        </div>
    );
}

export default Home;
